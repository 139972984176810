<template>
	<b-card-group>
		<b-card bg-variant="white">
			<h4 class="mb-4">
				{{ translate('personal_info') }}
			</h4>
			<is-loading
				:loading="loading"
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')" />
			<div class="table-responsive">
				<table
					v-show="!loading && Object.values(profileData).length > 0"
					class="table">
					<tbody>
						<template v-for="item in Object.keys(profileData)">
							<general-setting-field
								v-if="showField(item)"
								:key="item"
								:key-value="item"
								:field-information="checkFieldInformation(item)"
								:first-item="fieldName(Object.keys(profileData)[0])"
								:field-name="fieldName(item)"
								:errors="errors.errors[item]"
								:loading="loading"
								:verificate-phone-number="telComponent.includes(item)"
								@clearField="clearError(item)"
								@editField="(fieldValue, verificationPhoneToken) => editProfile(fieldValue, verificationPhoneToken, item)" />
						</template>
					</tbody>
				</table>
			</div>
		</b-card>
	</b-card-group>
</template>
<script>

import GeneralSettingField from '@/views/Users/components/GeneralSettingsField';
import ProfileInfo from '@/util/Profile';
import {
	EDITABLE_FIELDS as editableFields,
	CUSTOMER_EDITABLE_FIELDS as customerEditableFields,
	DATE_KEYS as dateKeys,
	BOOLEAN_KEYS as booleanKeys,
	TRANSLATED_KEYS as translatedKeys,
	EMAIL_KEY as emailKey,
	USERNAME_KEY as usernameKey,
	LABEL_TO_TRANSLATE as labels,
	PROFILE_INVISIBLE_FIELDS as invisibleFields,
	TEL_COMPONENT as telComponent,
} from '@/settings/Profile';
import {
	Profile, Validations, Users, Alerts, Countries, NIN,
} from '@/translations';
import { distributor, customerRoles } from '@/settings/Roles';
import { MDY_FORMAT } from '@/settings/Dates';

export default {
	name: 'Profile',
	messages: [Countries, Profile, Validations, Users, Alerts, NIN],
	components: { GeneralSettingField },
	data() {
		return {
			active: 'personal',
			alert: new this.$Alert(),
			booleanKeys,
			dateKeys,
			distributor,
			customerRoles,
			editableFields,
			customerEditableFields,
			profile: new ProfileInfo(),
			translatedKeys,
			emailKey,
			usernameKey,
			invisibleFields,
			telComponent,
		};
	},
	computed: {
		response() {
			try {
				const { data } = this.profile.data.response;
				return data;
			} catch (error) {
				return {};
			}
		},
		profileData() {
			try {
				return this.response.data.attributes;
			} catch (error) {
				return {};
			}
		},
		loading() {
			try {
				return this.profile.data.loading;
			} catch (error) {
				return false;
			}
		},
		errors() {
			try {
				return this.profile.data.errors;
			} catch (error) {
				return [];
			}
		},
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			this.profile.getProfile(this.$user.details().id);
		},
		editProfile(fieldValue, verificationPhoneToken, id) {
			const formVal = {};
			formVal[id] = fieldValue;
			if (this.telComponent.includes(id)) {
				formVal.verification_phone_token = verificationPhoneToken;
			}
			if (!this.emailKey.includes(id)) {
				this.profile.updateProfile(this.$user.details().id, formVal).then(() => {
					try {
						if (this.usernameKey.includes(id)) {
							this.$user.setUser({ username: fieldValue });
						}
						this.alert.toast('success', this.translate('profile_updated'));
					} catch (error) {
						this.alert.toast('success');
					}
					this.getData();
				}).catch(() => {});
			} else if (this.emailKey.includes(id)) {
				this.profile.updateProfile(this.$user.details().id, formVal).then(() => {
					this.getData();
					this.alert.confirmation(this.translate('swal_info'), this.translate('swal_info_sent_email'), { config: { showCancelButton: false, icon: 'warning' } }, false);
				}).catch(() => {});
			}
		},
		checkFieldInformation(item) {
			const fieldInformation = {};
			fieldInformation.value = this.profileData[item];
			if (this.dateKeys.includes(item)) {
				fieldInformation.value = this.$moment(this.profileData[item].date).format(MDY_FORMAT);
			} else if (this.translatedKeys.includes(item)) {
				fieldInformation.value = this.translate(this.profileData[item].toLowerCase());
			} else if (this.booleanKeys.includes(item)) {
				fieldInformation.value = !!this.profileData[item];
			}
			fieldInformation.editable = this.editableFields.includes(item) || (this.customerRoles.includes(this.$user.details().type) && this.customerEditableFields.includes(item));
			return fieldInformation;
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors.clear(field);
			}
		},
		showField(item) {
			return (this.profileData[item] || this.editableFields.includes(item)) && !this.invisibleFields.includes(item);
		},
		fieldName(key) {
			if (Object.keys(labels).includes(key)) {
				const newKey = labels[key];
				if (newKey === 'nin' && this.$user.details().country) {
					return this.translate(`nin_${this.$user.details().country.iso_code_2.toLowerCase()}`);
				}
			}
			return this.translate(key);
		},
	},
};
</script>
